import React, { useEffect, useState } from 'react';

// Css File
import '../css/flashmessage.css';

// Images Common File
import * as Img from './Img';

const FlashMessage = ({ show , message, type }) => { 
    const [isPopupVisible, setPopupVisible] = useState(show);

    useEffect(() => {
        // Sync local state with the show prop
        setPopupVisible(show);
    }, [show]);

    useEffect(() => {
        let popupTimer;

        if (isPopupVisible) {
            // Start a timer to hide the popup after 5 seconds
            popupTimer = setTimeout(() => {
                setPopupVisible(false);
            }, 3000);
        }

        return () => {
            // Clear the timer when the component unmounts or when isPopupVisible changes
            clearTimeout(popupTimer);
        };
    }, [isPopupVisible]);

    const handlefmp = () => {
        setPopupVisible(true);
    };

    const handlefmpclose = () => {
        setPopupVisible(false);
    };

    return (
        <>
            <div className="cmp-btn" onClick={handlefmp}><i className="fa fa-commenting-o" aria-hidden="true"></i></div>

            <div className={`flash-message ${isPopupVisible ? 'fmp-message' : ''}`}>
                <div className="flash-message-inner">
                    <span className="msg-close" onClick={handlefmpclose}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                        </svg>
                    </span>

                    {/* if Success img */}

                    {type === 'success' &&  <img className="flash-img" src={Img.success} alt="" /> }

                    {/* else Error img */}
                    {type === 'error' && <img className="flash-img" src={Img.error} alt="" />}

                    <div className="flash-text">
                        {message}
                    </div>
                </div>
                <div className={`flash-time-line ${type === 'error' ? 'ftl-red':''}`}></div>
            </div>

            <div className={` flash-message-popup ${isPopupVisible ? 'fmp-active' : ''} `} onClick={handlefmpclose}></div>
        </>
    );
};

export default FlashMessage;