import React from 'react'

// CountUp
import CountUp from 'react-countup';

// Icon Files
import Icons from '../../components/Icons';

// Css File
import "../../css/seonumbercard.css"

export default function Seonumber() {
  return (
    <div className="row snc-gap">
        <div className="col-lg-4">
            <div data-aos="fade-right" data-aos-delay="400" className="seo-number-card snc-bg snc-bg-1">
                <Icons.WorldSvg />
                <div className="snc-data-bx">
                    <div className="snc-number"><CountUp enableScrollSpy end={130}/>+ <br/> countries</div>
                    <div className="snc-text">We support 13 languages, so traders from all over the world could enjoy and profit anytime</div>
                </div>
            </div>
        </div>
        <div className="col-lg-8">
            <div className="row snc-gap">

                <div className="col-6" data-aos="fade-up" data-aos-delay="400">
                    <div className="seo-number-card">
                        <Icons.UserSvg />
                        <div className="snc-data-bx">
                        <div className="snc-number"><CountUp enableScrollSpy end={88}/>M+</div>
                        <div className="snc-text">Trader accounts</div>
                        </div>
                    </div>
                </div>

                <div className="col-6" data-aos="fade-up" data-aos-delay="400">
                    <div className="seo-number-card">
                        <Icons.TransactionsSvg />
                        <div className="snc-data-bx">
                        <div className="snc-number"><CountUp enableScrollSpy end={30}/>M+</div>
                        <div className="snc-text">Monthly transactions</div>
                        </div>
                    </div>
                </div>

                <div className="col-6" data-aos="fade-up" data-aos-delay="400">
                    <div className="seo-number-card">
                        <Icons.EwalletsSvg />
                        <div className="snc-data-bx">
                        <div className="snc-number">$<CountUp enableScrollSpy end={16}/>M+</div>
                        <div className="snc-text">Average monthly payouts</div>
                        </div>
                    </div>
                </div>

                <div className="col-6" data-aos="fade-up" data-aos-delay="400">
                    <div className="seo-number-card">
                        <Icons.ResetSvg />
                        <div className="snc-data-bx">
                        <div className="snc-number">$<CountUp enableScrollSpy end={211}/>M</div>
                        <div className="snc-text">Monthly trade turnover</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}
