import React from 'react'

// Icons File
import Icons from '../components/Icons';

// Img
// import payment1 from '../img/payment/logo(1).png'
import payment2 from '../img/payment/logo(2).png'
import payment3 from '../img/payment/logo(3).png'
import payment4 from '../img/payment/logo(4).png'
import payment5 from '../img/payment/logo(5).png'
import payment6 from '../img/payment/logo(6).png'
import payment7 from '../img/payment/logo(7).png'

// Css File
import "../css/payment-client.css"

export default function PaymentClient() {
  return (
    <>
        <div className="common-heading-bx text-center">
            <div className="common-heading ch-big">No limits. No commission. No delays.</div>
            <div className="common-sub-heading">Quick and easy deposits and withdrawals</div>
        </div>

        <div className="row mx-0 align-items-center justify-content-center mt-4 common-bg-color py-4 px-3 px-lg-3">

            <div className="col-md-6">
                <div className="common-heading-bx">

                    <div className="common-heading">Your money, your way</div>

                    <div className="check-list-bx mt-2">
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg /></div> Instant Deposit</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg /></div> Fast Withdrawal</div>
                        <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg /></div> 0% Commission</div>
                    </div>

                    <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="d-none d-md-flex common-btn-item cbi-fill mt-md-3"><span>Registration <Icons.RightArrowSvg /></span></div>

                </div>
            </div>

            <div className="col-md-6 col-sm-10 mt-4 mt-md-0">
                <div className="payment-imgs-bx">
                    <div className="payment-img-item">
                        <img src={payment7} alt="" />
                    </div>
                    <div className="payment-img-item">
                        <img src={payment2} alt="" />
                    </div>
                    <div className="payment-img-item">
                        <img src={payment3} alt="" />
                    </div>
                    <div className="payment-img-item">
                        <img src={payment4} alt="" />
                    </div>
                    <div className="payment-img-item">
                        <img src={payment5} alt="" />
                    </div>
                    <div className="payment-img-item">
                        <img src={payment6} alt="" />
                    </div>
                </div>
            </div>

        </div>

        <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="d-flex d-md-none common-btn-item cbi-fill mt-4 mx-auto"><span>Registration <Icons.RightArrowSvg /></span></div>
    </>
  )
}
