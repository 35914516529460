import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function PageDepositWithdrawalPolicy() {
  return (
    <>

      <Helmet>
        <title>Deposit & Withdrawal Policy | AirrsanFx</title>
      </Helmet>

      <section className="relative">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx pb-3">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Deposit & Withdrawal Policy</div>
            </div>
            <div className="tlm-heading">Deposit & Withdrawal Policy</div>
          </div>
        </div>
      </section>

      <section className="pb-5">
        <div className="container-lg cl-custome legal-content mt-4">

          <p>AirrsanFx limited (hereafter referred to as “AirrsanFx ltd”, “AirrsanFx”, ‘us’, ‘we’ and ‘our’), is Incorporated under the laws of saint lucia with registered address 1st floor, the sotheby Building,rodney bay, gros-islet,saint lucia p.O box 838,castries, saint lucia.. The company is a Licensed money brokerage regulated by labuan financial services authority.</p>


          <div className="main-lable">Bank Wire Transfer</div>
          <div className="main-sub-lable mt-2">Deposit</div>
          <p>When depositing by a Bank Transfer, you are required to use only a bank account, which is in
your name which should exactly match the name of the trading account. An authentic SWIFT
confirmation or a Wire Transfer Confirmation, showing the origin of the funds, must be sent to
our team once requested. Failure to submit the confirmation may result in the return of the
deposited amount, hence preventing such pending amounts from being deposited to your
AirrsanFx Limited.
</p>

          <div className="main-sub-lable">Withdrawals</div>
          <p>Any withdrawal of funds, from your AirrsanFx account to a bank account, can only be
refunded to the same bank account that the funds were originally received from or any other
bank account under client’s individual name. Please note, that the processing time of withdrawals
to reach your bank account substantially depends on the currency of the bank account, as well as
the location and the internal policies and procedures of the recipient’s financial institution and
country. Therefore, please expect the amounts withdrawn to be credited to your bank account
within 2 to 7 business days.
</p>

          <div className="main-lable">Credit/ Debit Cards</div>
          <div className="main-sub-lable mt-2">Deposit</div>
          <p>Credit/ Debit card deposits may be performed, according to the card companies' regulations.
Credit or Debit card deposits are processed instantly in case there is no need for additional
verification</p>
          <div className="main-sub-lable">Withdrawals</div>
          <p>When a withdrawal is performed the amounts withdrawn will be returned to the same card
used earlier for depositing. AirrsanFx cannot process the withdrawals made to any different
Card number or Card holder.</p>

<p>We are not liable for any transfer delays you may experience due to a disruption of service in
the system of the payment processor. AirrsanFx reserves the right to ask you to upload a
scanned color copy or photos of both the front and back pages of your card. It is advised that
you only leave the first 4 and last 4 digits of your card number visible in the image, and also
cover the CVV number from the back of your credit card. Please note that for cards that don’t
show cardholder name, we may require from the Client a documental proof of card ownership,
such as a confirmation letter or a Statement by the card issuer.</p>

<p>The company takes extreme precautions when dealing with security alerts for credit card
payments in order to protect our and our clients' financial integrity. For this reason, although we
strive to credit our clients' Credit/Debit card deposits as soon as possible, we do not undertake
nor promise or ensure immediate crediting after depositing, as in some cases additional
verification of payments is likely to be performed.</p>

<p>In some rare cases if your card does not belong to CFT/OCT programs, by which your card
issuer allows withdrawals above the deposited amount, we will not be able to process the
transaction. In this case you may withdraw your funds via bank transfer instead.
AirrsanFx cannot determine if your card belongs to this program and accepts such
transactions until we receive your withdrawal request. You should consult with your card issuer
for any further information.</p>


          <div className="main-sub-lable">Electronic Payment Methods</div>
          <p>When depositing funds using these facilities, you agree to, and acknowledge being bound by,
the regulations and rules of such service, including, but not limited to, fees and other restrictions.
When a withdrawal is requested, this may be performed only via the same payment method
clients use when initially depositing to your trading account.</p>
<p>Using electronic payment facilities as a means of transferring funds is at the discretion of each
client and we recommends that all policies and procedures regarding transfer, transaction limits,
fees, etc. are clearly understood by the client prior to the opening of an account or using their
services.</p>


          <div className="main-sub-lable">General Conditions</div>
          <p>All withdrawal requests are processed from Monday to Friday between 9am to 6pm Labuan
timing. All requests received outside of these hours will be processed on the next business day.</p>

<p>Funds deposit and withdrawal is only available for clients who have been identified and
assessed by AirrsanFx, and their contact details are confirmed. Clients would be required to
upload their supporting documents, such as a Photo ID and a Proof of an Address, and have their
trading account verified in order to have their funds deposited for trading.</p>

<p>Please note that AirrsanFx is legally required to return any Client’s deposits made into an
unverified trading accounts in case the verification procedures are not completed within 15 days
from the first deposit date.</p>

<p>Upon submitting a withdrawal request you may be required to submit a further
documentation as may be required from time to time by Anti Money Laundering (AML)
regulations, Credit/ Debit Card companies, Electronic Payment providers and AirrsanFx Limited.</p>

<p>AirrsanFx reserves the right to impose withdrawal limits and withdrawal fees in its system.</p>

<p>AirrsanFx cannot accommodate withdrawal requests for floating trading profits. A trading
position will need to be closed before any profits attributed to it may be withdrawn.</p>

          <div className="main-sub-lable">No Third-Party Payments are allowed under any circumstances.</div>
<p>This policy protects both us, and our clients against fraud and money laundering as AirrsanFxonly transfers funds back to the source of the original deposit.
AirrsanFx, at its sole discretion, may execute withdrawals to a facility other than the facility
used for the original deposit.</p>
<p>AirrsanFx reserves the right to require the client withdrawal method on the AirrsanFx’s
discretion.</p>
<p>Clients shall request, under all circumstances, to withdraw funds from their trading accounts inthe currency in which the deposit was originally made. Should the deposit currency differ from
the transfer currency, the transfer amount will be converted by AirrsanFx into the transfer
currency.</p>

          <div className="main-sub-lable">REFUND Conditions:</div>
<p>AirrsanFx provide clients with a clear and transparent refund policy. In cases, where clients
are not satisfied with the Company’s service level and did not make trading, investing, conversiontransactions, the Client can request a refund. The Company offers a 100% refund within 1 working
day of the original service deposit. The request will be reviewed within 5 business days.
After one day, deposit will no longer be refundable and will be treated as normal withdrawal
based on the existing withdrawal terms and conditions.</p>

<p>The Client agree not to request for a refund or chargeback from its payment method provider
at any time while or after using the Company’s services.</p>

<p>Profits or losses received as a result of the Client’s trading activity cannot be subject to refund
request.</p>

<p>Refunds should be made only to the payment system bank account / card client used to
deposit.</p>

<p>If the origins of the Client’s funds and/or the whole activities are contrary to the Company’s
Anti – Money Laundering Policy (AML Policy), the Company reserves unilateral rights to
cancel transferring of funds (deposit or withdraw) and freeze the assets to refund them back.</p>


          <div className="main-sub-lable">Dispute</div>
<p>Any dispute arising with or in connection to any of the terms and conditions of the WithdrawalPolicy shall be dealt by the Company as per the Company’s Complaint handling policy, which is
available on the Company’s website.</p>

<p>All complaints must be in writing and addressed to the Customer Support team of the
Company via email at <a href="mailto:support@airrsanfx.com" target="_blank" rel="noreferrer">support@airrsanfx.com</a></p>

<p>Please note that this policy cannot be exhaustive, and additional conditions or requirements
may apply at any time due to regulations and policies, including those set-in order to prevent
money laundering. Any and all usage of the site and services is subject to the Terms and
Conditions, as may be amended from time to time by AirrsanFx, at its sole discretion.</p>
          

        </div>
      </section>

    </>
  );
};
