import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MenuProvider } from '../components/Header/MenuContext';

//Header section
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";
import Header from "../components/Header/Header";

// Pages
import PageHome from "../containers/PageHome/PageHome";
import PageAbout from "../containers/PageAbout/PageAbout";
import PageContact from "../containers/PageContact/PageContact";

// Lagle Pages
import PageFAQs from "../containers/PagreLagle/PageFAQs";
import PagePrivacy from "../containers/PagreLagle/PagePrivacy";
import PageTermsAndConditions from "../containers/PagreLagle/PageTermsAndConditions";
import PageAMLPolicy from "../containers/PagreLagle/PageAMLPolicy";
import PageRestrictedCountries from "../containers/PagreLagle/PageRestrictedCountries";
import PageDepositWithdrawalPolicy from "../containers/PagreLagle/PageDepositWithdrawalPolicy";
import PageRiskDisclosure from "../containers/PagreLagle/PageRiskDisclosure";

// Footer section
import Layout from "./Layout";
import Footer from "../components/Footer/Footer";
// import FlashMessage from "../components/FlashMessage";
import RegistrationModal from "../components/RegistrationModal"


export const pages = [

  { path: "/", component: PageHome },
  { path: "/about", component: PageAbout },
  { path: "/contact", component: PageContact },
  { path: "/faqs", component: PageFAQs },
  { path: "/privacy-policy", component: PagePrivacy },
  { path: "/terms-and-conditions", component: PageTermsAndConditions },
  { path: "/aml-policy", component: PageAMLPolicy },
  { path: "/restricted-countries", component: PageRestrictedCountries },
  { path: "/deposit-withdrawal-policy", component: PageDepositWithdrawalPolicy },
  { path: "/risk-disclosure", component: PageRiskDisclosure },

];

const MyRoutes = () => {

  return (
    <>
      <Layout>
        <ScrollToTop />
        <Loader />
        <Header  />
        <main className="main-wrapper">
          <Routes>
            {pages.map(({ component: Component, path }, index) => {
              return <Route key={index} element={<Component />} path={path} />;
            })}
          </Routes>
        </main>
        <Footer />
        {/* <FlashMessage /> */}
        <RegistrationModal />
      </Layout>
    </>
  );
};

function MainRoutes() {
  return (
    <Router>
      <MenuProvider>
        <MyRoutes />
      </MenuProvider>
    </Router>
  );
}

export default MainRoutes;