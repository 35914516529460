import React, { useState } from 'react'

// Icons File
import Icons from '../../components/Icons';

// Imgs
import stocks from "../../img/icons/stocks.webp"
import forex from "../../img/icons/forex.webp"
import ftt from "../../img/icons/ftt.webp"
import phoneDefault from "../../img/bgimg/phone-default.webp"
import phoneForex from "../../img/bgimg/phone-forex.webp"
import phoneFtt from "../../img/bgimg/phone-ftt.webp"
import phonestocks from "../../img/bgimg/phone-stocks.webp"


// Css File
import "../../css/tradingmodes.css"

export default function TradingModes() {

    const [hoveredImage, setHoveredImage] = useState(phoneDefault);

    const handleMouseEnter = (image) => {
        setHoveredImage(image);
    };

    const handleMouseOut = () => {
        setHoveredImage(phoneDefault);
    };

    return (
        <div className="row">
            <div className="col-lg-8">
                <div className="common-heading-bx">
                    <div className="common-heading ch-big" style={{maxWidth:"489px"}}>Trading modes that fit your trading style</div>
                </div>

                <div className="row tmc-gap mt-4">

                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-delay="100" className="trading-modes-card" onMouseEnter={() => handleMouseEnter(phonestocks)} onMouseLeave={handleMouseOut}>
                        <div className="tmc-top-bx">
                            <div className="tmc-icons-bx">
                            <img src={stocks} alt="" />
                            <Icons.RightSvg />
                            </div>
                            <div className="tmc-heading">STOCKS</div>
                        </div>
                        <div className="tmc-bottom-bx">
                            <div className="tmc-text">Profit from the purchase and sale of assets</div>
                        </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-delay="200" className="trading-modes-card" onMouseEnter={() => handleMouseEnter(phoneForex)} onMouseLeave={handleMouseOut}>
                            <div className="tmc-top-bx">
                            <div className="tmc-icons-bx">
                                <img src={forex} alt="" />
                                <Icons.RightSvg />
                            </div>
                            <div className="tmc-heading">FOREX</div>
                            </div>
                            <div className="tmc-bottom-bx">
                            <div className="tmc-text">Best trading mode for constant learners</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div data-aos="fade-up" data-aos-delay="300" className="trading-modes-card" onMouseEnter={() => handleMouseEnter(phoneFtt)} onMouseLeave={handleMouseOut}>
                            <div className="tmc-top-bx">
                            <div className="tmc-icons-bx">
                                <img src={ftt} alt="" />
                                <Icons.RightSvg />
                            </div>
                            <div className="tmc-heading">FIXED TIME TRADES</div>
                            </div>
                            <div className="tmc-bottom-bx">
                            <div className="tmc-text">One of the simplest forms of trading today</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
                <div data-aos="fade-left" data-aos-delay="100" className="trading-modes-img-bx">
                    <img src={phoneDefault} alt="" />
                    {hoveredImage !== phoneDefault && (
                    <div className="trading-modes-hover-img-bx">
                        <img src={hoveredImage} alt="" />
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}
