import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function PageRestrictedCountries() {
  return (
    <>

      <Helmet>
        <title>Restricted Countries | AirrsanFx</title>
      </Helmet>

      <section className="relative">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx pb-3">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Restricted Countries</div>
            </div>
            <div className="tlm-heading">Restricted Countries</div>
          </div>
        </div>
      </section>

      <section className="pb-5">
        <div className="container-lg cl-custome legal-content mt-4">

          <p>AirrsanFx Limited does not provide any services to the following Country :</p>

          <ul className="ul-dick">
            <li>EU Member countries</li>
          </ul>

          <p>As per ongoing and substantial money laundering and financing of terrorism (ML/FT) risks - We do not offer accounts to residents of the following sanctioned countries which has deficiencies that pose a risk to the international financial system.</p>

          
          <ul className="ul-dick ul-flex">
            <li>Afghanistan</li>
            <li>Albania</li>
            <li>Angola</li>
            <li>Bahamas</li>
            <li>Barbados</li>
            <li>Botswana</li>
            <li>Cambodia</li>
            <li>Cote d'Ivoire (Ivory Coast)</li>
            <li>Crimea and Sevastopol</li>
            <li>Cuba</li>
            <li>Democratic People's Republic of Korea (DPRK)</li>
            <li>Democratic Republic of Congo</li>
            <li>Ghana</li>
            <li>Iceland</li>
            <li>Iran</li>
            <li>Iraq</li>
            <li>Jamaica</li>
            <li>Liberia</li>
            <li>Mauritius</li>
            <li>Mauritius</li>
            <li>Mongolia</li>
            <li>Myanmar</li>
            <li>Nicaragua</li>
            <li>Pakistan</li>
            <li>Panama</li>
            <li>Somalia</li>
            <li>Sudan</li>
            <li>Syria</li>
            <li>Trinidad and Tobago</li>
            <li>Uganda</li>
            <li>Yemen</li>
            <li>Zimbabwe</li>
          </ul>

        </div>
      </section>

    </>
  );
};
