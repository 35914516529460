import React from 'react'
import { Link } from 'react-router-dom'

// Img
import appstore from "../img/icons/appstore.png"
import playstore from "../img/icons/play_store.png"

export default function AppIcons() {
  return (
    <div className="app-store-bx">
        <Link to="/" className="asb-item">
            <img src={appstore} alt="" />
        </Link>
        <Link to="/" className="asb-item">
            <img src={playstore} alt="" />
        </Link>
    </div>
  )
}
