import React from "react";
import MyRouter from "./routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>AirrsanFx</title>
      </Helmet>


      <MyRouter />

    </HelmetProvider>
  );
}

export default App;





