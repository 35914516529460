import React from 'react'
import { TypeAnimation } from 'react-type-animation';

// Icons File
import Icons from "../../components/Icons"

// Css File
import "../../css/IndexSection.css"

export default function IndexSection() {
  return (
    <div className="textlayout-main-bx py-0 py-lg-5">
        <div data-aos="fade-up" className="tlm-heading" style={{height: 'auto', display: 'flex', alignItems: 'center', }}>
          <TypeAnimation
            sequence={[
              "They're Fast, We're Faster",
              700,
              "Welcome to AirrsanFx",
              700,
              "A single platform offering multiple opportunities.",
              700
            ]}
            wrapper="span"
            style={{ whiteSpace: 'pre-line', display: 'block', overflow:'hidden' }}
            speed={50}
            repeat={Infinity}
          />
        </div>
        {/* <div data-aos="fade-up" className="tlm-heading">They're <span className="tlm-h-color">Fast</span> <br/> We're Faster</div> */}
        <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">A trading platform that supports your financial goals.</div>
        <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
            <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="common-btn-item cbi-fill"><span>Registration <Icons.RightArrowSvg /></span></div>
            {/* <div className="common-btn-item cbi-outline"><span className='cbidata'>Funded account</span><span>Open Account</span></div> */}
        </div>
    </div>
  )
}
