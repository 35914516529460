import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function PageFAQs() {

  const questions = [
    {
      question: "What is AirrsanFx?",
      answer: (
        <>
            <p>AirrsanFx maintains high standards in its services, recognizing the pivotal role of quality for both the company and its clients. The belief in the necessity of versatile thinking and a unified set of business principles underpins our commitment to providing diverse forex services.</p>

            <p>We aim to adapt to evolving market conditions and consistently meet the diverse needs of our clientele.</p>

            <p>AirrsanFx stands out for several reasons:</p>

            <p><strong>Global Regulation</strong>: AirrsanFx is regulated globally, ensuring compliance with industry standards and providing a secure trading environment.</p>

            <p><strong>Nanosecond Execution</strong>: The platform boasts nanosecond execution, allowing for quick and efficient trade processing, which is crucial in fast-paced financial markets.</p>

            <p><strong>Lightning-Fast Withdrawals</strong>: AirrsanFx offers swift withdrawal processes, ensuring that you have timely access to your funds when needed.</p>

            <p><strong>Wide Range of Tradable Instruments</strong>: With over 300 tradable instruments, AirrsanFx provides a diverse portfolio, allowing traders to explore various markets and investment opportunities.</p>

            <p><strong>Tightest Spreads</strong>: AirrsanFx offers tight spreads, minimizing the difference between buying and selling prices, which can enhance profitability for traders.</p>
        </>
      ),
    },

    {
      question: "How do I start trading with AirrsanFx?",
      answer: (
        <>
             <p className='mt-1 mb-1' style={{fontSize:"16px"}}><strong>Create an Account</strong>:</p>
                {/* <p>Visit the official AirrsanFx website. 
                    {/ <a data-bs-target="#SignUpBtns" data-bs-toggle="modal">Register Link</a> /}
                    <a rel="noreferrer" href="https://my.AirrsanFx.com/en/register" target='_blank'>Register Link</a>
                </p> */}
                <p>Look for a "Register" or "Open an Account" option.</p>
                <p>Provide the required information, including personal details and contact information.</p>
                <p>Agree to the terms and conditions.</p>

                <p className='mt-4 mb-1' style={{fontSize:"16px"}}><strong>Verification (KYC)</strong>:</p>
                <p>Complete  the required identity verification process by submitting identification documents to comply with regulatory requirements.</p>

                <p className='mt-4 mb-1' style={{fontSize:"16px"}}><strong>Deposit Funds</strong>:</p>
                <p>Log in to your newly created account.</p>
                <p>Go to the deposit or funding section.</p>
                <p>Choose your preferred payment method and deposit funds into your trading account.</p>

                <p className='mt-4 mb-1' style={{fontSize:"16px"}}><strong>Select a Trading Platform</strong>:</p>
                <p>Choose from the available trading platform - MetaTrader 5 (MT5). </p>
                <p>Download and Install the Trading Platform.</p>

                <p className='mt-4 mb-1' style={{fontSize:"16px"}}><strong>Login to the Trading Platform</strong>:</p>
                <p>Open the trading platform.</p>
                <p>Enter your account credentials to log in.</p>
                <p>If you encounter any difficulties, AirrsanFx's customer support should be able to assist you. <Link to="/contact">Contact us</Link></p>
        </>
      ),
    },

    {
      question: "Which Financial instruments can I trade with AirrsanFx?",
      answer: (
        <>
             <p>Common categories of financial instruments that traders can potentially access on AirrsanFx:</p>
              <p><strong>Forex (Foreign Exchange)</strong>: Currency pairs, such as EUR/USD, GBP/JPY, USD/JPY, etc.</p>
              <p><strong>Indices</strong>: Benchmarks representing the performance of a group of stocks on a specific exchange such as S&P500, US30, etc.</p>
              <p><strong>Commodities</strong>: Such as oil, agricultural products, etc.</p>
              <p><strong>Precious Metals</strong>: Assets like gold, silver, platinum, palladium, etc.</p>
              <p><strong>Cryptocurrencies</strong>: Digital currencies like Bitcoin, Ethereum, Litecoin, etc.</p>
        </>
      ),
    },

    {
      question: "How can I deposit and withdraw funds on AirrsanFx?",
      answer: (
        <>
            <p>For clients convenience AirrsanFx provides deposit and withdrawal options via our Banking and Crypto network partners. Feel free to connect with our Client Servicing team for further assistance. </p>
        </>
      ),
    },

    {
      question: "What trading platforms does AirrsanFx support?",
      answer: (
        <>
            <p>AirrsanFx is available on prominent trading platform -  MetaTrader 5 (MT5).</p> 

            <p>MT5 is accessible for trading on various devices, including:</p>

            <p><strong>Computer</strong>: Via any web browser or a desktop application.</p>

            <p><strong>Android device</strong>: Through a web browser, the MT5 mobile app.</p>

            <p><strong>iOS device</strong>: Via a mobile web browser.</p>

            <p>Furthermore, you have the flexibility to open both demo and real accounts on all this platform, allowing you to explore and experience the features before engaging in live trading.</p>
        </>
      ),
    },

    {
      question: "Does AirrsanFx provide educational resources for traders?",
      answer: (
        <>
            <p>AirrsanFx, provides educational materials to help traders (Beginners. intermediate or expert) improve their skills and knowledge in the forex market.</p>

            <p>Typically, the forex educational resources may include:</p>

            <p><strong>Training Videos</strong>: Video tutorials covering various aspects of forex trading, from basics to advanced strategies.</p>

            <p><strong>Educational Articles and Guides</strong>: Written content explaining key concepts, trading strategies, risk management, and market analysis.</p>

            <p><strong>Demo Accounts</strong>: Virtual trading accounts that allow users to practice trading in a risk-free environment.</p>

            <p><strong>Market Analysis</strong>: Regular market analysis reports to help traders understand market trends.</p>

            {/* <p>You can also click on the education  section and additionally, you can contact our customer support for more detailed information. <Link to="/education">(Education link)</Link>.</p> */}
        </>
      ),
    },

  ];

  return (
    <>

      <Helmet>
        <title>FAQs | AirrsanFx</title>
      </Helmet>

      <section className="relative">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx pb-3">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ FAQs</div>
            </div>
            <div className="tlm-heading">FAQs: Your Guide to Seamless Trading</div>
            <div className="tlm-sub-text">Unlock the answers you need with our FAQs. From account setup to trading strategies, find quick solutions to common queries, ensuring your trading journey with AirrsanFx is smooth and successful.</div>
          </div>
        </div>
      </section>

      <section className="pb-5">
          <div className="container-lg cl-custome legal-content">
            <div id="faqsaccordion" className="faqs-accordion  px-0 mt-4"> {/*  data-bs-parent="#faqsaccordion" */}
              {questions.map((item, index) => (
                <div data-aos="fade-up" className="acc-item" key={index}>
                    <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                        {item.question}
                        <span className="down-icon di-two"></span>
                    </div>

                    <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                        <div className="faqs-data">
                            {item.answer}
                        </div>
                    </div>
                </div>
              ))}
            </div>
          </div>
      </section>

    </>
  );
};
