import React from 'react'

// Icons File
import Icons from '../../components/Icons';

// Css File
import "../../css/account-steps-info.css"

export default function AccountStepsInfo() {
  return (
    <>
      <div className="common-heading-bx text-center">
        <div className="common-heading ch-big">Open an account in 4 simple steps</div>
      </div>
      <div className="row mt-4 asic-gap">
          <div className="col-lg-3 col-sm-6">
              <div data-aos="fade-Up" data-aos-delay="100" className="account-steps-infp-card">
                  <div className="asic-num">1</div>
                  <div className="asic-data-bx">
                      <div className="asic-heading">Register</div>
                      <div className="asic-text max-i">Choose an account type and complete our fast and secure application form</div>
                  </div>
              </div>
          </div>

          <div className="col-lg-3 col-sm-6">
              <div data-aos="fade-Up" data-aos-delay="200" className="account-steps-infp-card">
                  <div className="asic-num">2</div>
                  <div className="asic-data-bx">
                      <div className="asic-heading">Verify</div>
                      <div className="asic-text max-i">Use our digital onboarding system for fast verification</div>
                  </div>
              </div>
          </div>

          <div className="col-lg-3 col-sm-6">
              <div data-aos="fade-Up" data-aos-delay="300" className="account-steps-infp-card">
                  <div className="asic-num">3</div>
                  <div className="asic-data-bx">
                      <div className="asic-heading">Fund</div>
                      <div className="asic-text max-i">Fund your trading account using a wide range of funding methods</div>
                  </div>
              </div>
          </div>

          <div className="col-lg-3 col-sm-6">
              <div data-aos="fade-Up" data-aos-delay="400" className="account-steps-infp-card">
                  <div className="asic-num">4</div>
                  <div className="asic-data-bx">
                      <div className="asic-heading">Trade</div>
                      <div className="asic-text max-i">Start trading on your live account and access +2,100 instruments</div>
                  </div>
              </div>
          </div>
      </div>
      <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="mx-auto common-btn-item cbi-fill mt-4"><span>Registration <Icons.RightArrowSvg /></span></div>
    </>
  )
}
