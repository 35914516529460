import React, { useState, useEffect }  from 'react';
import { Link, NavLink } from "react-router-dom";

// Css Files
import '../../css/header.css';

// Images Common File
import * as Img from '../Img';

// Icon Files
import Icons from '../Icons';

// Design File
import { useMenu } from './MenuContext';
import Sidemenu from './Sidemenu';

export default function Header() {

    // --- Header Js Start --- //
    const [headerSticky, setheaderSticky] = useState("");

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 10) {
          setheaderSticky("active");
        } else {
          setheaderSticky("");
        }
      };
  
      // Attach the event listener when the component mounts
      window.addEventListener('scroll', handleScroll);
  
      // Remove the event listener when the component unmounts to avoid memory leaks
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts
  
    // --- Header Js End --- //


    // --- ---
    // const [isDropActive, setIsDropActive] = useState(false);

    // const handleMouseEnterDrop = () => {
    //   setIsDropActive(true);
    // };

    // const handleMouseLeaveDrop = () => {
    //   setIsDropActive(false);
    // };

    // const handleDropClick = () => {
    //   setIsDropActive(false); // Remove active class when an item is clicked
    // };
    // --- ---

    const { toggleMenu } = useMenu();
    // const { handleScreenChange } = useMenu();

    return (
      <>
        {/* <Topstrip /> */}
        
        <header className={`maxwidth1920 custom-header ${headerSticky}`}>
          <div className="ch-inner">

            <div className="ch-left">
              {/* Website Logo Start */}
                <Link to="/" className="ch-logo">
                  <img className="logo-img" src={Img.logo_anim} alt="" />
                  <img className="icon-anim" src={Img.icon_anim} alt="" />
                  <img className="icon-img" src={Img.icon} alt="" />
                </Link>
              {/* Website Logo End */}
            </div>

            {/* Website Menu Start */}
            <div className="ch-menu-bx">

              {/* <div className={`cmb-drop-item ${isDropActive ? 'active' : ''}`} onMouseEnter={handleMouseEnterDrop} onMouseLeave={handleMouseLeaveDrop}>
                <div className="cmb-item">
                  Markets<Icons.DownSvg />
                  <div className="cmb-item-line"></div>
                </div>
                <div className={`cmb-drop-bx ${isDropActive ? 'active' : ''}`}>
                  <div className="cmb-drop-inner">
                    <div className="cdi-main" onClick={handleDropClick}>
                      <Link to="/" className="cdi-main-item">Forex</Link>
                      <Link to="/" className="cdi-main-item">Indices</Link>
                      <Link to="/" className="cdi-main-item">Crypto</Link>
                      <Link to="/" className="cdi-main-item">Metals</Link>
                      <Link to="/" className="cdi-main-item">Commodities</Link>
                      <Link to="/" className="cdi-main-item">Oil</Link>
                    </div>
                  </div>
                </div>
              </div> */}

              <NavLink to="/" className="cmb-item">
                Home
                <div className="cmb-item-line"></div>
              </NavLink>

              <NavLink to="/about" className="cmb-item">
                About Us
                <div className="cmb-item-line"></div>
              </NavLink>

              <NavLink to="/contact" className="cmb-item">
                Contact Us
                <div className="cmb-item-line"></div>
              </NavLink>

            </div>
            {/* Website Menu End */}


            <div className="ch-right">

              {/* Signup Btns Start */}
              <div className="signinup-btn-bx"> 
                {/* <Link to="/" className="sip-btn">Login</Link> */}
                {/* <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="sip-btn register-sip-btn ms-2" sbdata="Sign Up"><span className='sbdata'>Sign Up</span><span>Sign Up</span></div> */}
                <div data-bs-target="#RegistrationModal" data-bs-toggle="modal" className="common-btn-item cbi-fill"><span>Sign Up<Icons.RightArrowSvg /></span></div>
              </div>
              {/* Signup Btns ENd */}

              <div className="menu-icon-bx" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>

            </div>
            
          </div>
        </header>

        <Sidemenu />
      </>
    );
}
  