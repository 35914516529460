import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Layout = ({ children }) => {
    useEffect(() => {
        AOS.init({
            offset: 0,
            easing: 'ease-out',
            duration: 600,
            delay:300,
        });
    }, []);

    return <>{children}</>;
};

export default Layout;
