import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet-async";

// Img
import banner from "../../img/bgimg/banner(01).webp"

// Video
import video1 from "../../img/video/video1.webm"


// Design File
import IndexSection from './IndexSection';
import Seonumber from './Seonumber';
import TradingModes from './TradingModes';
import PlatformWorks from './PlatformWorks';
import BecomePro from './BecomePro';
import AccountStepsInfo from './AccountStepsInfo';
import PaymentClient from '../PaymentClient';
import StoreSection from '../../components/StoreSection';
import BannerSection from '../BannerSection';


export default function PageHome() {
  
  const videoRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
      // Set a timer to show the video after 5 seconds
      const timer = setTimeout(() => {
          setShowVideo(true);
          if (videoRef.current) {
              videoRef.current.play();
          }
      }, 5000);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>AirrsanFx</title>
      </Helmet>

        <div className={`common-page-bg-bx ${showVideo ? 'cpbg-op0' : 'cpbg-op1'}`}>
          <img src={banner} alt='' />
        </div>

        <div className={`common-page-bg-bx ${showVideo ? 'cpbg-op1' : 'cpbg-op0'}`}>
            <video ref={videoRef} poster={banner} autoPlay preload muted loop>
                <source src={video1} type="video/webm" />
            </video>
        </div>

      <section className="py-5">
        <div className="container-lg cl-custome">

          <div className="py-lg-5">
            <IndexSection />
          </div>
    
          <div className="pt-lg-5 pt-5 pb-lg-5 mt-5 overflowhidden">
            <Seonumber />
          </div>
     
          <div className="pt-lg-5 pt-5 pb-lg-5 mt-5 overflowhidden">
            <TradingModes />
          </div>

          <div className="pt-lg-5 pt-5 pb-lg-5 mt-5">
            <PlatformWorks />
          </div>

          <div className="pt-lg-5 pt-5 pb-lg-5 mt-5 overflowhidden">
            <BecomePro />
          </div>

          <div className="pt-lg-5 pt-5 pb-lg-5 mt-5">
            <PaymentClient />
          </div>

          <div className="pt-lg-5 pt-5 pb-lg-5 mt-5">
            <AccountStepsInfo />
          </div>

          <div className="pt-lg-5 pt-5 pb-lg-5 mt-5">
            <StoreSection />
          </div>

        </div>
      </section>

      <section className="py-5 common-bg-style cbs1">
        <div className="container-lg cl-custome">
          <BannerSection />
        </div>
      </section>

    </>
  );
};
